import {
  simpleGet,
  postFile,
  simplePost,
} from "../../services/genericRequests";
import { downloadFile } from "../../services/utils";
import { eventBus } from "../../services/eventBus";

export async function getRecipes({ commit }, payload) {
  commit("startLoading");
  const response = await simplePost({
    path: "recipes/getRecipes",
    data: payload,
  });
  commit("setRecipes", response.data);
  commit("stopLoading");
  return response;
}

export async function getRecipe({ commit }, payload) {
  if (payload) {
    commit("clearRecipeState");
    commit("startSingleLoading");
    const response = await simpleGet({ path: `recipes/getRecipe/${payload}` });
    if (response.data) {
      commit("setRecipe", response.data.data);
    }
    commit("stopSingleLoading");
    return response.data;
  }
}

export async function assignRecipe({ commit, dispatch }, payload) {
  if (payload.assignable) {
    commit("clearRecipeState");
    commit("startSingleLoading");
    const response = await simplePost({
      path: `recipes/assignRecipe`,
      data: payload,
    });
    console.log(response);
    if (response.status === 200) {
      commit("setRecipe", response.data.data);
    }
    commit("stopSingleLoading");
    dispatch("getRecipes");
    return response;
  } else {
    eventBus.$emit("makeAlert", {
      message: `La receta no corresponde al período actual.`,
      color: "red",
    });
  }
}

export async function getRecipeStatus({ commit }, payload) {
  if (payload) {
    commit("clearRecipeStatus");
    commit("startRecipeStatusLoading");
    const response = await simpleGet({
      path: `recipes/getRecipeStatus/${payload}`,
    });
    if (response.status === 200) {
      commit("setRecipeStatus", response.data);
    }
    commit("stopRecipeStatusLoading");
    return response;
  }
}

export async function setReception({ commit, dispatch }, payload) {
  commit("startRecipeStatusLoading");
  const response = await simpleGet({ path: `renglon/setReception/${payload}` });
  if ((response.status = 200)) {
    dispatch("getRecipeStatus", response.data.id);
    dispatch("getRecipes"); /* No */
  }
  commit("stopRecipeStatusLoading");
}

export async function setDispensation({ commit, dispatch }, payload) {
  commit("startRecipeStatusLoading");
  const response = await simpleGet({
    path: `renglon/setDispensation/${payload}`,
  });
  if ((response.status = 200)) {
    dispatch("getRecipeStatus", response.data.id);
    dispatch("getRecipes"); /* No */
  }
  commit("stopRecipeStatusLoading");
}

export async function markReturn({ commit }, payload) {
  commit("startReturnLoading");
  await postFile({ path: `recipes/markReturn`, data: payload }).then(
    (response) => {
      downloadFile(response.data, `Devolucion-${payload.id}.pdf`);
    }
  );
  commit("stopReturnLoading");
}

export async function getRecipeReturn({ commit }, payload) {
  commit("startReturnLoading");
  const response = await simpleGet({
    path: `recipes/getRecipeReturn/${payload}`,
  });
  if (response.status === 200) {
    commit("setRecipeReturn", response.data.data);
  }
  commit("stopReturnLoading");
  return response;
}

// Iosper

export async function getMovementsIosperUser({ commit }, payload) {
  commit("startLoading");
  const response = await simpleGet({ path: "iosper/getUserMovements" });
  if (response.data) {
    commit("setIosperMovements", response.data);
  }
  commit("stopLoading");
}

export async function getRecipeIosper({ commit }, payload) {
  console.log("asd");
  commit("startSingleLoading");
  const response = await simpleGet({ path: "iosper/getRecipe/" + payload });
  if (response.data) {
    commit("setRecipe", response.data.recipe);
  }
  commit("stopSingleLoading");
  return response.data;
}
